<template>
  <BaseDialog
    :title="displayText.dialogTitle"
    width="576px"
    :btn1Disabled="disabledControl.btn.confirm"
    @confirm="onConfirm"
    @close="$emit('close')"
  >
    <div style="margin-bottom: 40px">
      <BaseElForm ref="formRef" :model="formData" :rules="formRules">
        <BaseElFormItem prop="amount">
          <div class="flex items-center justify-evenly" style="gap: 8px">
            <span>使用</span>
            <ElInputWrapper>
              <BaseElInputNumber
                v-model="formData.amount"
                :step="1"
                step-strictly
                style="max-width: 350px;"
                :max="maxDiscount"
              />
            </ElInputWrapper>
            <span>{{ displayText.pointUnit }}，折抵 {{ displayData.curComputedDiscountPrice }} 元</span>
          </div>
        </BaseElFormItem>
      </BaseElForm>

      <div>
        每 {{ displayData.pointDiscountRate }} {{ displayText.pointUnit }}折 1 元
      </div>
      <div>
        現有{{ displayText.pointName }} {{ displayData.pointBalance }}，折扣後剩餘 {{ displayData.remainingPoint }}
      </div>

      <div class="text-danger flex justify-between">
        <span>折扣金額</span>
        <span>-$ {{ displayData.discountPrice }}</span>
      </div>
    </div>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent } from 'vue'
import BaseDialog from './Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { get } from 'lodash'
import { maxNumberRules, minRules, noEmptyRules } from '@/validation'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'SalesPOSPointPartDiscountModal',
  components: {
    BaseDialog,
    ElInputWrapper,
  },
  props: {
    scope: {
      type: String,
      required: true,
    },
    maxPrice: {
      type: Number,
    },
  },
  setup (props, { emit }) {
    const { context, configData, getActiveCartItem, orderUnpaidPrice, computedUsedResource } = useSalesPOSCreate()
    const { formRef, formData, initFormData, checkForm, loading } = useBaseForm()
    const { defaultShopPoint } = useShop()
    initFormData({
      amount: 0,
    })

    const pointRateRules = () => {
      const pointDiscountRate = get(configData, 'pointDiscountRate', 1) // 點數折扣率
      return {
        trigger: ['change', 'blur'],
        validator: (rule, value, callback) => {
          // 如果非 pointDiscountRate 的倍數，則提示錯誤
          if (value % pointDiscountRate !== 0) {
            callback(new Error(`請輸入 ${pointDiscountRate} 的倍數`))
          } else {
            callback()
          }
        },
      }
    }

    const displayText = computed(() => {
      const pointName = get(defaultShopPoint.value, 'name', '點數')
      const text = {
        dialogTitle: `${pointName}部分折抵`,
        pointUnit: get(defaultShopPoint.value, 'unitName', '點'),
        pointName,
      }
      return text
    })

    const formRules = computed(() => {
      const preUsedPoint = get(computedUsedResource.value, 'point', 0) // 目前已使用點數
      const pointBalance = get(configData, 'consumerData.pointBalance', 0) // 會員原擁有的點數
      const remainingPointBalance = pointBalance - preUsedPoint // 目前剩餘點數

      let maxNumMessage
      if (formData.amount > remainingPointBalance) {
        maxNumMessage = `${displayText.value.pointName}餘額不足`
      }

      const rules = {
        amount: [noEmptyRules(), minRules(1), pointRateRules(), maxNumberRules(remainingPointBalance, maxNumMessage)],
      }
      return rules
    })

    const maxDiscount = computed(() => {
      const pointDiscountRate = get(configData, 'pointDiscountRate', 1) // 點數折扣率
      if (props.maxPrice) {
        return props.maxPrice * pointDiscountRate
      }

      const preUsedPoint = get(computedUsedResource.value, 'point', 0) // 目前已使用點數
      const pointBalance = get(configData, 'consumerData.pointBalance', 0) // 會員原擁有的點數
      const remainingPointBalance = pointBalance - preUsedPoint // 目前剩餘點數
      return remainingPointBalance

      // let max
      // if (props.scope === 'singleDiscount') {
      //   const activeCartItem = getActiveCartItem()
      //   const itemRemainingPrice = activeCartItem.getDiscountRemainingPrice()
      //   const total = itemRemainingPrice * pointDiscountRate
      //   if (remainingPointBalance < total) {
      //     return remainingPointBalance
      //   }
      //   max = total
      // } else if (props.scope === 'orderDiscount') {
      //   const total = orderUnpaidPrice.value * pointDiscountRate
      //   if (remainingPointBalance < total) {
      //     return remainingPointBalance
      //   }
      //   max = total
      // }

      // return max
    })

    const disabledControl = computed(() => {
      const controls = {
        btn: {
          confirm: false,
        },
      }

      if (formData.amount <= 0) {
        controls.btn.confirm = true
      } else if (formData.amount > maxDiscount.value) {
        controls.btn.confirm = true
      }

      return controls
    })

    const displayData = computed(() => {
      const preUsedPoint = get(computedUsedResource.value, 'point', 0) // 目前已使用點數
      const pointBalance = get(configData, 'consumerData.pointBalance', 0) // 會員原擁有的點數
      const remainingPointBalance = pointBalance - preUsedPoint // 目前剩餘點數
      const pointDiscountRate = get(configData, 'pointDiscountRate', 1) // 點數折扣率
      const curComputedDiscountPrice = Math.floor(formData.amount / pointDiscountRate) // 目前計算的折扣金額

      let discountPrice

      if (props.scope === 'singleDiscount') {
        const activeCartItem = getActiveCartItem()
        const itemRemainingPrice = activeCartItem.getDiscountRemainingPrice()
        // 如果計算的折扣金額大於商品剩餘金額，則折扣金額為商品剩餘金額
        if (curComputedDiscountPrice >= itemRemainingPrice) {
          discountPrice = itemRemainingPrice
        } else {
          discountPrice = curComputedDiscountPrice
        }
      } else if (props.scope === 'orderDiscount') {
        // 如果計算的折扣金額大於訂單剩餘金額，則折扣金額為訂單剩餘金額
        if (curComputedDiscountPrice >= orderUnpaidPrice.value) {
          discountPrice = orderUnpaidPrice.value
        } else {
          discountPrice = curComputedDiscountPrice
        }
      }

      return {
        pointBalance: remainingPointBalance, // 會員剩餘點數
        remainingPoint: remainingPointBalance - formData.amount, // 折扣後剩餘點數
        pointDiscountRate, // 點數折扣率
        curComputedDiscountPrice, // 目前計算的折扣金額
        discountPrice, // 折扣金額
      }
    })

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const pass = await checkForm(formRef.value)
      loading.value = false
      if (!pass) return
      emit('confirm', {
        pointUsed: formData.amount,
        savePrice: displayData.value.discountPrice,
      })
      emit('close')
    }

    return { displayText, formRef, formData, onConfirm, displayData, formRules, disabledControl, maxDiscount }
  },
})
</script>

<style lang="postcss" scoped>

</style>
